import Config, { ENV_DEVELOPMENT, ENV_STAGING } from "core/model/config";
import trimDecrypted from "core/model/encryption/trimDecrypted";
import { isCI } from "core/model/utils/featureFlags";
import { Auction, GetOntologyType } from "core/types";
import { Locale as DateFnsLocale } from "date-fns";
import { padding } from "ds_legacy/materials/metrics";
import { FieldsHolderType } from "dsl/organisms/PatientMenu/transform";
import { StaticFaxCoverLetter } from "dsl/static/StaticFaxCoverLetter";
import { StaticPatientProfile } from "dsl/static/StaticPatientProfile";
import ReactDOMServer from "react-dom/server";
import { Locale as TranslationsLocale } from "translations";
import Translations from "translations/types";

// https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
function b64EncodeUnicode(str: string) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode(parseInt("0x" + p1, 16));
      },
    ),
  );
}

function b64DecodeUnicode(str: string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );
}

export function getPatientProfileHTML({
  auction,
  currentLocale,
  encryptionAvailable,
  extractedPatientFields,
  getOntology,
  locale,
  translations,
  type,
}: {
  auction: Auction;
  currentLocale: DateFnsLocale;
  encryptionAvailable: boolean;
  extractedPatientFields: FieldsHolderType;
  faxPrint?: boolean;
  forClinic?: boolean;
  getOntology: GetOntologyType;
  locale: TranslationsLocale;
  translations: Translations;
  type: number;
}) {
  const safeAuction = trimDecrypted(auction);

  /**
   * Caution: Do not use hooks within renderToStaticMarkup!
   *
   * Reason: React hooks rely on the component lifecycle, and calling hooks within
   * renderToStaticMarkup will break the rules of hooks. This is because renderToStaticMarkup
   * runs outside of React's regular rendering lifecycle, meaning hooks will not behave as expected.
   * Using hooks here can lead to unexpected behavior and errors. It's important to only call hooks
   * inside functional components or custom hooks, where the component lifecycle is guaranteed.
   */
  const patientProfileHTML = ReactDOMServer.renderToStaticMarkup(
    <>
      <div>
        <StaticFaxCoverLetter
          auction={safeAuction}
          faxType={type}
          getOntology={getOntology}
          translations={translations}
        />
      </div>
      <div style={{ padding: padding(0) }}>
        <StaticPatientProfile
          auction={safeAuction}
          currentLocale={currentLocale}
          extractedPatientFields={extractedPatientFields}
          faxPrint
          getOntology={getOntology}
          locale={locale}
          translations={translations}
          encryptionAvailable={encryptionAvailable}
        />
      </div>
    </>,
  );

  const content =
    `<!DOCTYPE html>` +
    `<html>` +
    `<head><meta charset="UTF-8" /></head>` +
    patientProfileHTML +
    `</html>`;

  const base64encodedContent = b64EncodeUnicode(content);

  if (
    (Config.environment === ENV_DEVELOPMENT ||
      Config.environment === ENV_STAGING) &&
    !isCI
  ) {
    const newWindow = window.open("about:blank", "", "_blank");
    if (!newWindow)
      console.log("could not open new window to show fax document");
    else newWindow.document.write(b64DecodeUnicode(base64encodedContent));
  }

  return base64encodedContent;
}
