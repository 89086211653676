import {
  HOME_CARE_SOLUTIONS,
  MEDICAL_SUPPLIES_SOLUTIONS,
  SOLUTION_FULL_TIME_MOBILE_CARE,
  SOLUTION_HELP_AT_HOME,
  SOLUTION_MOBILE_CARE,
  TRANSPORT_SOLUTIONS,
} from "core/consts";
import { preprocessZipcode, validateZipcode } from "core/model/utils/location";
import {
  convertModelDefinition,
  encryptedDef,
  required,
} from "react-forms-state";

const houseNumberSolutions = [
  SOLUTION_MOBILE_CARE,
  SOLUTION_HELP_AT_HOME,
  SOLUTION_FULL_TIME_MOBILE_CARE,
  ...TRANSPORT_SOLUTIONS,
  ...MEDICAL_SUPPLIES_SOLUTIONS,
  ...HOME_CARE_SOLUTIONS,
];

export const requiredHouseNumber = (solutions: Array<number> | undefined) => {
  if (!solutions) return false;
  return !solutions.every((s) => houseNumberSolutions.indexOf(s) < 0);
};

export const getModelDefinition = (
  country: any,
  encryptionActivated: boolean,
) =>
  convertModelDefinition({
    city: {
      out: "city",
      default: null,
      validate: required(),
    },
    street: {
      out: "street",
      default: null,
      validate: required(),
      convertOut: (value: any) => value.trim(),
    },
    floor: {
      out: "floor",
      default: null,
      convertOut: (value: any) => value?.trim() || null,
    },
    house_number: {
      out: "house_number",
      default: null,
      validate: (value: any, props: any) =>
        requiredHouseNumber(props.solutions) && encryptionActivated
          ? required()(value)
          : true,
    },
    ...encryptedDef("encrypted_house_number"),
    zipcode: {
      out: "zipcode",
      default: null,
      // @ts-expect-error
      convertOut: preprocessZipcode(),
      validate: validateZipcode(true),
    },
    country: {
      out: "country",
      default: null,
      convertOut: () => country,
    },
  });
