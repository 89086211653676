import clsx from "clsx";
import {
  ASSESSMENT_VARIANTS,
  SEARCH_TYPES,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
  SOLUTION_MOBILE_CARE,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  typeOfCareToRights,
} from "core/consts";
import {
  isAuctionRunning,
  isCareSearch,
  isHospitalSearch,
  isMultiSolutionSearch,
  isRehabSearch,
} from "core/model/auctions";
import {
  checkAllowedDuplicateSearchType,
  getSolutionsForPatientType,
  getSpecializations,
  showMultipleSpecializations,
  showSpecializations,
} from "core/model/patients";
import { OntologyType } from "core/model/utils/ontologies";
import {
  useGetOntologies,
  useGetOntology,
} from "core/model/utils/ontologies/hooks";
import { getValues } from "core/model/utils/ontologies/utils";
import { getQueryVariable } from "core/model/utils/urls";
import {
  Account,
  Auction,
  Careseeker,
  GetOntologyType,
  Patient,
  QueryProgress,
  SearchType,
} from "core/types";
import { getUnixTime } from "date-fns";
import { Banner } from "ds/ui";
import CheckboxInputField from "ds_legacy/components/CheckboxInputField";
import DatePickerInputField from "ds_legacy/components/DatePickerInputField";
import InfoCaption from "ds_legacy/components/InfoCaption";
import ConnectedRadioChipGroup from "ds_legacy/components/RadioChipGroup";
import RadioGroup from "ds_legacy/components/RadioGroup";
import { SelectConnectedWithForm } from "ds_legacy/components/Select";
import ConnectedOntologySelectInput from "ds_legacy/components/SelectInput/Ontology";
import TextInputField from "ds_legacy/components/TextInputField";
import { dp, margin, padding } from "ds_legacy/materials/metrics";
import {
  FONT_SIZE_14,
  Subheading,
  Title,
} from "ds_legacy/materials/typography";
import OntologyCheckboxGroup from "dsl/atoms/OntologyCheckboxGroup";
import { AsteriskExplained } from "dsl/molecules/AsteriskExplained";
import ExternalIdKisField from "dsl/molecules/ExternalIdKisField";
import OntologyRadioGroup from "dsl/molecules/OntologyRadioGroup";
import { FormWatcher, Show } from "react-forms-state";
import { useFormRendererContext } from "react-forms-state/src/Context";
import { useLocation } from "react-router-dom";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { defaultSolutionsForSearchType } from "./modelDefinition";

export type PatientCreateFormProps = {
  account: Account;
  careseeker: Careseeker;
  className?: string;
  getOntology: GetOntologyType;
  onPatientNameChange?: (s: (o: AnyObject) => AnyObject) => void;
  onSubmit: (value: Auction) => void;
  queryProgress: QueryProgress;
  roles?: number[];
  translations: Translations;
};

export const textLabelWidth = dp(350);

export const solutionSortOrder = {
  "8": 1,
  "1": 2,
  "2": 3,
  "4": 4,
  "5": 5,
  "3": 6,
};
const patientTypeSortOrder = {
  "1": 1,
  "2": 2,
};

function searchTypeDisabled({
  careseeker,
  patient,
  roles,
  searchType,
}: {
  careseeker: Careseeker;
  patient?: Patient;
  roles?: number[];
  searchType: SearchType;
}): { disable: boolean; hide: boolean } {
  if (!roles && searchType === SEARCH_TYPE_CARE)
    return { hide: false, disable: false };

  const duplicateAllowed = checkAllowedDuplicateSearchType({
    searchType,
    auctions: patient?.auctions,
  });

  if (!duplicateAllowed) return { hide: false, disable: true };

  const disabledByRoles = !typeOfCareToRights[searchType]?.some(
    (right) => roles?.includes(right),
  );

  const disabledByCareseeker = !careseeker.patient_types?.includes(searchType);

  const disabledSearchType = disabledByRoles || disabledByCareseeker;

  if (!disabledByRoles && disabledByCareseeker) {
    console.warn(
      `user with role for search type: ${searchType} for careseeker ${careseeker.id} without available patient type in: ${careseeker.patient_types}`,
    );
  }
  if (
    (
      [
        SEARCH_TYPE_HOME_CARE,
        SEARCH_TYPE_MEDICAL_SUPPLIES,
        SEARCH_TYPE_TRANSPORT,
      ] as number[]
    ).includes(searchType)
  ) {
    return { hide: disabledByCareseeker, disable: disabledByRoles };
  }
  return { hide: false, disable: disabledSearchType };
}

const showFlexibleStartDate = (searchType: SearchType): boolean => {
  return !(
    [
      SEARCH_TYPE_TRANSPORT,
      SEARCH_TYPE_REHABILITATION,
      SEARCH_TYPE_MEDICAL_SUPPLIES,
      SEARCH_TYPE_HOME_CARE,
    ] as number[]
  ).includes(searchType);
};

const formatStation = ({ id = -1, name = "", description = "" }) => ({
  label: `${name}${description ? ` - ${description}` : ""}`,
  id,
  value: id,
});

export function getPatientTypeOptions({
  careseeker,
  getOntology,
  patient,
  roles,
  translations,
}: {
  careseeker: Careseeker | null;
  getOntology: AnyFunction;
  patient?: Patient;
  roles?: number[];
  translations: Translations;
}) {
  if (!careseeker) return [];

  return SEARCH_TYPES.map((searchType) => {
    const { disable, hide } = searchTypeDisabled({
      careseeker,
      searchType,
      roles,
      patient,
    });
    if (hide) return null;
    return {
      name: getOntology({ type: "patientType", key: searchType }),
      value: searchType,
      tooltipText: disable ? translations.patient.upgradePlan : undefined,
      disabled: disable,
    };
  }).truthy();
}

const getSolutionOptions = (patientType: SearchType) =>
  getValues("solution")
    .map((id) => parseInt(id as string))
    .filter((sol) => getSolutionsForPatientType(patientType).includes(sol));

function useFilteredOptions({
  exclude,
  include,
  type,
}: {
  exclude?: number[] | null;
  include?: number[] | null;
  type: OntologyType;
}) {
  const getOntologies = useGetOntologies();
  let values = getOntologies({ type, sort: true });

  if (include) {
    values = values.filter((v) => include?.includes(v.value));
  }

  if (exclude) {
    values = values.filter((v) => exclude && !exclude.includes(v.value));
  }
  return values.map(({ name: label, value }) => ({
    label,
    value,
    id: value,
  }));
}

function SpecializationSelect({
  careseeker,
  required,
  searchType,
}: {
  careseeker: Careseeker;
  required?: boolean;
  searchType: SearchType;
}) {
  const translations = useTranslations();
  const specializations = getSpecializations({
    searchType,
    careseeker,
  });
  const showMultiple = showMultipleSpecializations({ searchType });

  const options = useFilteredOptions({
    type: "specializations",
    include: specializations,
  });

  return (
    <SelectConnectedWithForm
      elementName="specializations"
      label={
        showMultiple ? translations.ontologies.specializations.key : undefined
      }
      multiple={showMultiple}
      options={options}
      required={required}
      width="100%"
      placeholder={translations.general.selectDefaultPlaceholderShort}
    />
  );
}

function ShortAssessmentSelect() {
  const translations = useTranslations();

  return (
    <RadioGroup
      label={translations.patient.complexityPatient}
      elementName="assessment_variant"
      marginBox={margin(1, 0, 0)}
      labelStyle={{ fontSize: FONT_SIZE_14 }}
      marginWrapper={margin(1, 0, 0, -0.5)}
      options={[
        {
          // @ts-ignore
          value: "mobile-short",
          label: translations.careseeker.shortMobileAssessment,
          id: 1,
        },
        {
          // @ts-ignore
          value: "",
          label: translations.careseeker.longMobileAsssessment,
          id: 2,
        },
      ]}
    />
  );
}

function SolutionsSelect({ searchType }: { searchType: SearchType }) {
  const translations = useTranslations();

  if (isMultiSolutionSearch(searchType)) {
    return (
      <OntologyCheckboxGroup
        elementName="solutions"
        include={getSolutionOptions(searchType)}
        label={translations.patient.solutionLabel}
        labelStyle={{
          margin: margin(0),
          fontSize: FONT_SIZE_14,
        }}
        order={solutionSortOrder}
        sideMutation={(value, mutateElement) => {
          mutateElement(null, "assessment_variant");
        }}
        type="solution"
        wrapperStyle={{ margin: margin(0) }}
        required
      />
    );
  }
  return (
    <OntologyRadioGroup
      elementName="solutions"
      type="solution"
      label={translations.patient.solutionLabel}
      includeOptions={getSolutionOptions(searchType)}
      required
      margin={margin(1, 0)}
      labelStyle={{
        fontSize: FONT_SIZE_14,
      }}
    />
  );
}

function SearchTypeField({
  careseeker,
  isCreating,
  roles,
}: {
  careseeker: Careseeker;
  isCreating: boolean;
  roles?: Array<number>;
}) {
  const { resetValidation } = useFormRendererContext();
  const getOntology = useGetOntology();
  const translations = useTranslations();
  const patientOptions = getPatientTypeOptions({
    translations,
    roles,
    getOntology,
    careseeker,
  });

  const sideMutation = (
    newValue: SearchType,
    mutateElement: (s: any, k: string) => void,
  ) => {
    resetValidation([
      "specializations",
      "solutions",
      "start_date",
      "care_duration_in_days",
    ]);

    mutateElement(defaultSolutionsForSearchType(newValue), "solutions");
    mutateElement(null, "care_duration_in_days");

    const today = getUnixTime(new Date());
    const patientTypeSpecializations = getSpecializations({
      searchType: newValue,
      careseeker,
    });

    if (isHospitalSearch(newValue)) {
      mutateElement(today, "start_date");
    }

    if (patientTypeSpecializations.length == 1) {
      mutateElement(
        {
          label: getOntology({
            type: "specializations",
            key: patientTypeSpecializations[0],
          }),
          value: patientTypeSpecializations[0],
          id: patientTypeSpecializations[0],
        },
        "specializations",
      );
    } else {
      mutateElement(null, "specializations");
    }
  };

  return (
    <ConnectedRadioChipGroup
      disabled={!isCreating}
      elementName="search_type"
      formLabelSx={{
        padding: padding(0),
        fontSize: FONT_SIZE_14,
      }}
      id="search_type"
      label={translations.patient.patientType}
      options={patientOptions}
      radioGroupSx={{
        margin: margin(1, 0, 0),
        width: textLabelWidth,
      }}
      required
      sideMutation={sideMutation}
      sortOrder={patientTypeSortOrder}
    />
  );
}

export function PatientCreateForm({
  auction,
  careseeker,
  isCreating,
  roles,
}: {
  auction?: Auction;
  careseeker: Careseeker;
  isCreating: boolean;
  roles?: Array<number>;
}) {
  const translations = useTranslations();
  const location = useLocation();
  const externalIdQueryVar =
    getQueryVariable(location.search, "external_id") ||
    auction?.patient?.external_id;

  const searchStarted = auction && isAuctionRunning(auction.status);
  const stationOptions = careseeker.stations_full?.map(formatStation) ?? [];

  return (
    <div className="flex max-w-xl flex-col space-y-4 px-4 pb-8">
      {isCreating && (
        <>
          <AsteriskExplained customMargin={margin(1, 0, 0, 0)} />
          <Title style={{ margin: padding(1, 0) }}>
            {translations.patient.createPatient}
          </Title>
        </>
      )}

      <Subheading bold margin={margin(1, 0)}>
        {translations.patient.hospitalStay}
      </Subheading>

      <div className="flex flex-col space-y-2">
        <ExternalIdKisField
          careseeker={careseeker}
          textLabelWidth={textLabelWidth}
          externalIdQueryVar={externalIdQueryVar}
        />

        {careseeker.stations_full && (
          <div data-testid="station">
            <SelectConnectedWithForm
              elementName="station_full_id"
              withPortal
              sideMutation={(
                value: { id: number } | undefined,
                mutateElement: (v: any, name: string) => void,
              ) => {
                if (value) {
                  const station = careseeker.stations_full?.find(
                    ({ id }) => id == value.id,
                  );
                  if (station?.phone_number) {
                    mutateElement(
                      station.phone_number,
                      "doctor_in_charge_in_hospital_phone",
                    );
                  }
                }
              }}
              width={textLabelWidth}
              options={stationOptions}
              value={stationOptions.length === 1 && stationOptions}
              label={translations.patient.station}
              placeholder={translations.general.selectDefaultPlaceholderShort}
              fontSize={FONT_SIZE_14}
            />
          </div>
        )}
        <div>
          <TextInputField
            elementName="doctor_in_charge_in_hospital_phone"
            width={textLabelWidth}
            label={translations.patient.stationPhoneNumber}
            hasCustomValidation
            marginOverride={margin(1, 0)}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <Subheading bold margin={margin(0)}>
          {translations.patient.transfer}
        </Subheading>
        {searchStarted && (
          <InfoCaption text={translations.patient.transferChanges} />
        )}
      </div>
      <div>
        <SearchTypeField
          careseeker={careseeker}
          isCreating={isCreating}
          roles={roles}
        />
      </div>
      <FormWatcher watchPath="search_type">
        {({ watchedValue: searchType }) => {
          const isSearchTypeHidden = [
            SEARCH_TYPE_MEDICAL_SUPPLIES,
            SEARCH_TYPE_HOME_CARE,
          ].includes(searchType);
          return (
            <>
              {searchType && (
                <div hidden={isSearchTypeHidden}>
                  <SolutionsSelect searchType={searchType} />
                </div>
              )}

              <FormWatcher watchPath="solutions">
                {({ watchedValue: solutions }) => {
                  const showSpecialization = showSpecializations({
                    searchType,
                  });
                  const showShortMobileAssessmentSelect =
                    solutions?.length == 1 &&
                    solutions[0] === SOLUTION_MOBILE_CARE;

                  return (
                    <>
                      <div
                        hidden={!showSpecialization}
                        style={{ width: textLabelWidth }}
                      >
                        <SpecializationSelect
                          required
                          careseeker={careseeker}
                          searchType={searchType}
                        />
                      </div>
                      {isRehabSearch(searchType) && (
                        <>
                          <div>
                            <DatePickerInputField
                              elementName="operation_date"
                              hasCustomValidation
                              inputSx={{
                                width: textLabelWidth,
                                margin: margin(0),
                              }}
                              key="operation_date"
                              label={
                                translations.patient.medicalDiagnosis
                                  .operationDate
                              }
                              required
                            />
                          </div>
                          <div>
                            <TextInputField
                              elementName="operation_description"
                              label={
                                translations.patient.medicalDiagnosis
                                  .operationDescription
                              }
                              marginOverride={margin(0)}
                              multiline
                              placeholder={
                                translations.patient.medicalDiagnosis
                                  .operationDescriptionPlaceholder
                              }
                              width={textLabelWidth}
                            />
                          </div>
                        </>
                      )}
                      <div>
                        <DatePickerInputField
                          disablePast
                          elementName="start_date"
                          inputSx={{
                            width: textLabelWidth,
                            margin: margin(0),
                          }}
                          key="start_date"
                          label={translations.patient.expectedStartDate}
                          required
                        />
                      </div>
                      <div hidden={!isRehabSearch(searchType)}>
                        <DatePickerInputField
                          disablePast
                          elementName="discharge_date"
                          inputSx={{
                            width: textLabelWidth,
                            margin: margin(0),
                          }}
                          key="discharge_date"
                          label={translations.patient.expectedDischargeDate}
                          hasCustomValidation
                        />
                      </div>
                      {showFlexibleStartDate(searchType) && (
                        <Show elementName="start_date_flexible">
                          <div>
                            <CheckboxInputField
                              elementName="start_date_flexible"
                              label={translations.patient.flexibleDate}
                              margin={margin(0, 0, 0, -1.5)}
                            />
                          </div>
                        </Show>
                      )}
                      {isCareSearch(searchType) && (
                        <>
                          <div style={{ width: textLabelWidth }}>
                            <ConnectedOntologySelectInput
                              elementName="care_duration_in_days"
                              placeholder={translations.patient.careDuration}
                              label={translations.patient.careDuration}
                              required
                              type="careDurations"
                              fullWidth
                              style={{ border: "2px solid red" }}
                              size="small"
                            />
                          </div>
                          <div
                            hidden={
                              !solutions?.includes(
                                SOLUTION_SHORT_TERM_STATIC_CARE,
                              )
                            }
                          >
                            <CheckboxInputField
                              elementName="interested_long_term_stay"
                              label={
                                translations.patient.interestedStayLongTerm
                              }
                              margin={margin(0, 0, 0, -1.5)}
                            />
                          </div>
                        </>
                      )}
                      <div
                        className={clsx("hidden flex-col", {
                          "!flex": showShortMobileAssessmentSelect,
                        })}
                      >
                        <ShortAssessmentSelect />
                      </div>
                      <div
                        className={clsx("hidden flex-col space-y-4", {
                          "!flex": isRehabSearch(searchType),
                        })}
                      >
                        <div>
                          <CheckboxInputField
                            elementName="direct_transfer_necessary"
                            label={translations.patient.directTransferNecessary}
                          />
                        </div>
                        <div>
                          <CheckboxInputField
                            elementName="assessment_variant"
                            label={
                              translations.patient
                                .electivePatientNotInHospitalYet
                            }
                            customValue={ASSESSMENT_VARIANTS.ELECTIVE_REHAB}
                          />
                        </div>
                        <Banner
                          noIcon
                          color="primary"
                          message={
                            translations.careproviderApp.settings.payers
                              .infofieldRehabShortInsurances
                          }
                        />
                      </div>
                    </>
                  );
                }}
              </FormWatcher>
            </>
          );
        }}
      </FormWatcher>
    </div>
  );
}
