import { margin } from "ds_legacy/materials/metrics";
import { IndicatorsContainerProps } from "react-select";
import { SelectOption } from "./types";

export function IndicatorsContainer({
  children,
  selectProps: { classes },
}: IndicatorsContainerProps<SelectOption>) {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        gap: margin(1),
      }}
      className={classes.IndicatorsContainerProps}
    >
      {children}
    </div>
  );
}
