import {
  AUCTION_STATUS_CANCELLED,
  AUCTION_STATUS_NOT_STARTED,
} from "core/consts";
import { Auction, Careseeker, Patient } from "core/types";
import { useMemo } from "react";

function arrayEqual(array1: Array<number>, array2: Array<number>) {
  return (
    array1.length === array2.length &&
    array1
      .sort((a, b) => a - b)
      .every(function (value, index) {
        return value === array2.clone().sort((a, b) => a - b)[index];
      })
  );
}

export function withBreakingChange(
  auction: Auction,
  updatedSolutions: number[] | number | null | undefined,
  assessmentVariant: string | undefined,
  assessmentVariantRef: string | undefined,
): boolean {
  const existingSolutions = auction?.solutions;
  if (assessmentVariant != assessmentVariantRef)
    assessmentVariantRef = assessmentVariant;

  // In the case of transport, solutions is a number and not an array of numbers
  const updatedSolutionsArray: number[] | null = Array.isArray(updatedSolutions)
    ? updatedSolutions
    : updatedSolutions
    ? [updatedSolutions]
    : null;

  if (
    auction.assessment_completed &&
    updatedSolutionsArray &&
    existingSolutions &&
    !arrayEqual(updatedSolutionsArray, existingSolutions)
  )
    return true;

  // assessmentVariant can be null or ""
  if (
    auction.assessment_completed &&
    (assessmentVariant || auction?.assessment_variant) &&
    assessmentVariant != auction?.assessment_variant
  ) {
    return true;
  }

  return false;
}

export const canStartTheSearch = (
  patient: Patient,
  auction: Auction | undefined,
) => {
  if (!patient) return false;

  if (
    auction?.status &&
    auction.status !== AUCTION_STATUS_NOT_STARTED &&
    auction.status !== AUCTION_STATUS_CANCELLED
  )
    return true;

  return !!auction?.assessment_completed;
};

export function useCanStartTheSearch(
  patient: Patient,
  auction: Auction | undefined,
) {
  return useMemo(() => canStartTheSearch(patient, auction), [patient, auction]);
}

export const composeAnd =
  (
    ...functions: Array<
      (props: { careseeker?: Careseeker; formInputValue: Auction }) => boolean
    >
  ) =>
  (props: { careseeker?: Careseeker; formInputValue: Auction }) =>
    functions.every((f) => f(props));

export const blacklist =
  (key: string) =>
  ({ careseeker }: { careseeker?: Careseeker }) => {
    if (!careseeker?.config?.blacklisted_assessment_fields) return true;
    return !careseeker.config.blacklisted_assessment_fields.includes(key);
  };
